<template>
	<div>
		<h3 class="mt-3">Users component</h3>

        <v-data-table dark
			:headers="headers"
			:items="items"
			:items-per-page="10"
			class="elevation-1"
		></v-data-table>

		<!-- <div v-for="user in users" :key="user.id">
			{{ user.inaam }}
		</div> -->
	</div>
</template>

<script>
//import axios from "axios";
import { getUsers} from "@/api/user.js";

export default {
	name: "User",

	data: () => ({
		items: [],
		bLoaded: false,
		headers: [
			{
				text: "Inlognaam",
				value: "inaam",
				align: "start",
				sortable: true,
			},
			{ text: "Naam", value: "naam" },
            { text: "Email", value: "email" },
            { text: "Rol", value: "rol" },
            { text: "Token date", value: "token_date" },
            { text: "Token", value: "token" },
		],
	}),

	mounted() {  
        //this.getUsers();  //inhoud method kan ook meteen hier in mounted staan ipv in this.getUsers();	 
	},
    created() {
        getUsers().then((apiData) => {
            console.log("getUsers() apiData:", apiData);
			if (apiData.bSuccess) {
                    this.bLoaded = true;
                    this.items = apiData.data;
                } else {
                    this.$root.$emit("showSnackbar", {
                        text: apiData.sMessage,
                        type: "error",
                        time: 7000,
                    });
                }
            return null //response.data.data
        });
	},
	methods: {
		// getUsers() {
		// 	axios
		// 		.get("http://localhost/api_vue/api.php?action=getUsers")
		// 		.then((response) => {
		// 			this.bLoaded = true;
		// 			this.users = response.data.data;
		// 			console.log("response users=", response);
        //             if(!response.data.bSuccess) {
        //                 let snackbarSettings = {
        //                     text: response.data.sMessage,
        //                     time: -1,
        //                 }
        //                 this.$root.$emit('showSnackbar', snackbarSettings)
        //             }
		// 		});
		// },
	},

    // computed: {
    //     info_login: function () {
    //         if(this.$store.state.module_login.info_login) {
    //             this.getUsers();
    //             return true
    //         } else {
    //             return false
    //         }
    //     }
    // },

    // watch: {
    //     info_login:function(val, val2) {
    //         console.log("val = ", val, "val2 = ", val2)
    //         if(val) {
    //             this.getUsers();
    //             console.log("ingelogd en users opgehaald");
    //         } else {
    //             console.log("NIET ingelogd du GEEN users opgehaald");
    //         }
           
    //     },
        
    // }
};
</script>
